import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'
import { addFace, deleteFace, fetchFaceApplyInfo, fetchFaces, getFaceImageKeys } from './facesApi'
import type { AddFaceRequest, Face, FaceApplyInfo, FaceImageKey } from './facesType'


export interface facesState {
  faces: Face[]
  appliyInfos: FaceApplyInfo[]
  ssecKeys: {[key: string]: string}
  status: 'unload' | 'loading' | 'loaded'
}

const initialState: facesState = {
  faces: [],
  appliyInfos: [],
  ssecKeys: {},
  status: 'unload',
}

export const fetchFacesAsync = createAsyncThunk(
  'faces/fetch',
  async (): Promise<Face[] | null> => {
    const response = await fetchFaces().catch((err) => {
      console.log('faces fetch error' + err)
    })
    if (response) {
      return response.data ?? []
    } else {
      return null
    }
  }
)

export const fetchApplyInfosAsync = createAsyncThunk(
  'faces/applyInfo',
  async (): Promise<FaceApplyInfo[] | null> => {
    const response = await fetchFaceApplyInfo().catch((err) => {
      console.log('faces fetch apply info error' + err)
    })
    if (response) {
      return response.data
    } else {
      return null
    }
  }
)

export const getFaceKeysAsync = createAsyncThunk(
  'faces/keys',
  async (): Promise<FaceImageKey[]> => {
    const response = await getFaceImageKeys().catch((err) => {
      console.log('faces keys get error' + err)
      return []
    })
    return response
  }
)

export const addFaceAsync = createAsyncThunk('faces/create', async ([floor, tenantId]: [AddFaceRequest, string]): Promise<Face[]|undefined> => {
  await addFace(floor, tenantId)
  return (await fetchFaces())?.data
})

export const deleteFaceAsync = createAsyncThunk('faces/delete', async ([myFaceId, myFaceImageId, tenantId]: [string, string, string]): Promise<Face[]|undefined> => {
  await deleteFace(myFaceId, myFaceImageId, tenantId)
  return (await fetchFaces())?.data
})

export const facesSlice = createSlice({
  name: 'faces',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFacesAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchFacesAsync.fulfilled, (state, action) => {
        state.status = 'loaded'
        if (!action.payload) return
        state.faces = action.payload
      })
      .addCase(addFaceAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(addFaceAsync.fulfilled, (state, action) => {
        state.status = 'loaded'
        state.faces = action.payload ?? []
      })
      .addCase(deleteFaceAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(deleteFaceAsync.fulfilled, (state, action) => {
        state.status = 'loaded'
        state.faces = action.payload ?? []
      })
      .addCase(fetchApplyInfosAsync.fulfilled, (state, action) => {
        if (!action.payload) return
        state.appliyInfos = action.payload
      })
      .addCase(getFaceKeysAsync.fulfilled, (state, action) => {
        state.ssecKeys = action.payload.reduce((p, c) => {
          p[c.tenantId] = c.sseCKey
          return p
        }, {} as {[key: string] : string})
      })
    },
})


export const selectFaces = (state: RootState) => state.faces.faces
export const selectFacesStatus = (state: RootState) => state.faces.status
export const selectFacesApplyInfo = (state: RootState) => state.faces.appliyInfos
export const selectFaceImageKeys = (state: RootState) => state.faces.ssecKeys

export default facesSlice.reducer
