import { api } from '../Api'
import { camelizedParser, kebabize } from '../utils'
import type { AddFaceRequest, FaceImageKey, FaceImageKeysResponse, FacesApplyInfoResponse, FacesResponse } from './facesType'

export async function fetchFaces(): Promise<FacesResponse | undefined> {
  const res = await api.get(`/maintenance/face/list`)
  const json = await res.text()
  return camelizedParser(json)
}

const o2form = (obj: Object): FormData => {
  const formData = new FormData()
  Object.entries(obj).forEach(([key, value]) => {
    formData.append(kebabize(key), value)
  })
  return formData
}

export async function addFace(face: AddFaceRequest, tenantId: string) {
  await api.post(`/maintenance/tenant/${tenantId}/admin/face-add`, {
    body: o2form(face)
  }, true)
}

export async function deleteFace(myFaceId: string, myFaceImageId: string, tenantId: string) {
  await api.delete(`/maintenance/tenant/${tenantId}/admin/face/${myFaceId}/${myFaceImageId}`)
}

export async function fetchFaceApplyInfo(): Promise<FacesApplyInfoResponse> {
  const res = await api.get(`/maintenance/face-apply-info/list`)
  const json = await res.text()
  return camelizedParser(json)
}

export async function getFaceImageKeys(): Promise<FaceImageKey[]> {
  const res = await api.get(`/maintenance/face-image-key`)
  return camelizedParser<FaceImageKeysResponse>(await res.text()).data
}
